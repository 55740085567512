import React from 'react';

const Footer = ({ isFixed = false }) => {
  return (
      <div className="footer-box" style={{ position: isFixed ? 'absolute' : 'relative'}}>
        <p className="footer-text-standard">©The Cypherverse ‘22</p>
        <div style={{display: 'inline-block', alignSelf: 'center', alignContent: 'center'}}>
          <a className="footer-text-standard footer-text-link" style={{cursor: 'pointer'}}
             href="https://terms.cdlounge.io" target="_blank" rel="noreferrer">Terms</a>
          <a className="footer-text-standard footer-text-link" style={{marginLeft: 10, cursor: 'pointer'}}
             href="https://privacy.cdlounge.io" target="_blank" rel="noreferrer">Privacy</a>
        </div>
      </div>
  );
};

export default Footer;
