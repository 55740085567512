import React from "react";
import styled from "styled-components";
import { CdlColors } from "../../themes/Colors";

const StyledButton = styled.button`
  justify-content: ${(props) => (props.fullWidth ? "center" : "unset")};
  width: ${(props) => (props.fullWidth ? "100%" : "unset")};
  display: flex;
  font-family: IBM Plex Sans;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: ${(props) => (props.size === "medium" ? "42px" : "52px")};
  border-radius: 10px 2px 2px 2px;
  border: ${(props) => {
    if (props.noBorder) {
      return "unset";
    }
    if (props.customBorderColor) {
      return `1px solid ${props.customBorderColor}`;
    }
    return props.type === "primary" && "unset";
  }};
  padding: ${(props) => (props.size === "medium" ? "10px 19px" : "17px 21px")};
  max-width: ${(props) => (props.fullWidth ? "100%" : "180px")};
  align-items: center;
  background: ${(props) => {
    if (props.disabled) {
      return props.customDisabledBgColor || "#2A4060";
    } else {
      return props.customButtonBgColor
        ? props.customButtonBgColor
        : props.type === "primary"
        ? "linear-gradient(86.26deg, #8C86FF 3.09%, #AB5FB4 50.76%, #CC3767 100%)"
        : "transparent";
    }
  }};
`;

const Text = styled.div`
  color: ${(props) => props.customTextColor || CdlColors.white};
  font-size: 15px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const IconLeft = styled.img`
  margin-right: 9px;
`;
const IconRight = styled.img`
  margin-left: 14.5px;
`;

const Button = ({
  iconLeft,
  iconLeftSize,
  iconRight,
  size = "medium",
  type = "primary",
  fullWidth = false,
  text,
  onClick,
  isDisabled = false,
  customBorderColor,
  customTextColor,
  customDisabledBgColor,
  noBorder,
  customButtonBgColor,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      size={size}
      onClick={onClick}
      disabled={isDisabled}
      customBorderColor={customBorderColor}
      customDisabledBgColor={customDisabledBgColor}
      noBorder={noBorder}
      customButtonBgColor={customButtonBgColor}
      fullWidth={fullWidth}
      {...rest}
    >
      {iconLeft && (
        <IconLeft src={iconLeft} alt={iconLeft} width={iconLeftSize} />
      )}
      <Text customTextColor={customTextColor}>{text}</Text>
      {iconRight && <IconRight src={iconRight} alt={iconRight} />}
    </StyledButton>
  );
};

export { Button };
