import React, {useEffect} from 'react';

import colour_band from "../../assets/imgs/colour_band.png";
import diamond_pip from "../../assets/imgs/diamond_pip.png";
import {CdlColors} from "../../themes/Colors"
import Footer from "../../components/footer/Footer";

const HomePage = () => {
    useEffect(() => {
        const colorBandEl = document.getElementById("color-band")
        const mainEl = document.getElementById("main-element")

        let timer0 = setTimeout(() => {
            mainEl.style.opacity = '1'
        }, 1);

        let timer1 = setTimeout(() => colorBandEl.classList.add("color-band-first-anim"), 600);


        return () => {
            clearTimeout(timer0);
            clearTimeout(timer1);
        };
    }, []);

    return (
        <div className="page-faq">
            <div id="main-element" style={{
                backgroundColor: CdlColors.backgroundFaq,
                width: '100%',
                display: 'flex',
                opacity: 0,
                transition: 'all 1s linear',
                flexDirection: 'column',
                paddingBottom: 10,
            }}>
                <img id="color-band" className="color-band" src={colour_band} style={{margin: 'auto', zIndex: 0}}
                     alt="Band"/>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <h1 className="faq-header">FAQ</h1>
                </div>
                <div className="main-box">
                    <div className="why-dream why-dream-left">
                        <ul style={{listStyleImage: `url(${diamond_pip})`}}>
                            <li>
                                <span>
                                    What’s the Cosmic Dream Lounge?
                                </span>
                            </li>
                        </ul>
                        <p>
                            CDL is a cozy space cruise set on <span style={{fontStyle: 'italic'}}>the VHS Arcadia</span>.
                            You can board the Arcadia to join our
                            community of wayward elves drifting among the stars. Together, we’ll journey to the heart of
                            the cosmos and find a haven to call home. A place that makes you feel better, feel accepted,
                            and feel seen. So come on in, settle into your Cabin, and get ready for the Dream Adventure
                            of a lifetime. Oh, and be sure to check out that pocket device we’ve left for you in your
                            Cabin…

                        </p>
                        <ul style={{listStyleImage: `url(${diamond_pip})`}}>
                            <li>
                                <span>
                                    Who’s the team?
                                </span>
                            </li>
                        </ul>
                        <p>
                            CDL is dreamt up by <a
                            href="https://cypherverse.io" target="_blank" rel="noreferrer">The Cypherverse</a> - A
                            world-class creative studio specializing in
                            world-building, web3 gaming, and NFT innovation. CDL and their genesis project, <a
                            href="https://voxodeus.io" target="_blank" rel="noreferrer">VoxoDeus</a>,
                            are both key entries in the ever-expanding Cypherverse.
                        </p>
                    </div>
                    <div className="why-dream why-dream-right">
                        <ul style={{listStyleImage: `url(${diamond_pip})`}}>
                            <li>
                                <span>
                                    What are the mint details?
                                </span>
                            </li>
                        </ul>
                        <p>
                            Mint details are still star dust … but we will be launching Fall 2022. Join our <a
                            href="https://discord.gg/cdlounge" target="_blank" rel="noreferrer">Discord</a> and follow
                            our <a href="https://twitter.com/cdlounge" target="_blank" rel="noreferrer">Twitter</a> to
                            hear when the stars align.
                        </p>
                        <ul style={{listStyleImage: `url(${diamond_pip})`}}>
                            <li>
                                <span>
                                    How to get in touch?
                                </span>
                            </li>
                        </ul>
                        <p>
                            We’ve got ears for you!<br/><br/>
                            <span><strong>-</strong> Join <a
                                href="https://discord.gg/cdlounge" target="_blank" rel="noreferrer">Discord</a> and hit us up in  <code>#collab-request</code> or <code>#support</code></span><br/>
                            <span><strong>-</strong> Email at <a
                                href="mailto:hello@cypherverse.io" target="_blank"
                                rel="noreferrer">hello@cypherverse.io</a></span><br/>
                            <span><strong>-</strong> DM our head of Marketing and Community<br/>
                            <li>NFSteve #6959 on Discord</li>
                            <li>@NFSteveCrypto on Twitter</li>
                                </span>
                        </p>
                    </div>
                </div>
                <div className="wake-up-text">wake up and
                    start dreaming
                </div>
                <Footer isFixed={false}/>
            </div>
        </div>
    )
        ;
};

export default HomePage;
