import React, {useEffect} from 'react';

import colour_band from "../../assets/imgs/colour_band.png";
import computer from "../../assets/imgs/computer.png";
import {CdlColors} from "../../themes/Colors"
import Footer from "../../components/footer/Footer";

const HomePage = () => {
    useEffect(() => {
        const colorBandEl = document.getElementById("color-band")
        const mainBoxEl = document.getElementById("main-box")
        const manifestHeaderEl = document.getElementById("manifesto-header")
        const whyDreamEl = document.getElementById("why-dream")
        const computerEl = document.getElementById("computer")
        const footerBoxEl = document.getElementById("footer-box-home")
        const mainEl = document.getElementById("main-element")

        let timer0 = setTimeout(() => {
            mainEl.style.opacity = '1'
        }, 1);

        let timer1 = setTimeout(() => colorBandEl.classList.add("color-band-first-anim"), 400);

        let timer2 = setTimeout(() => mainBoxEl.classList.add("main-box-first-anim"), 800);

        let timer3 = setTimeout(() => manifestHeaderEl.classList.add("manifesto-header-first-anim"), 1400);

        let timer4 = setTimeout(() => whyDreamEl.classList.add("why-dream-first-anim"), 1600);

        let timer5 = setTimeout(() => computerEl.classList.add("computer-first-anim"), 1800);

        let timer13 = setTimeout(() => footerBoxEl.classList.add("footer-box-home-first-anim"), 2000);

        return () => {
            clearTimeout(timer0);
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
            clearTimeout(timer5);
            clearTimeout(timer13);
        };
    }, []);

    return (
        <div className="page-manifesto">
            <div id="main-element" style={{
                backgroundColor: CdlColors.backgroundManifesto,
                width: '100%',
                display: 'flex',
                opacity: 0,
                transition: 'all 1s linear',
                flexDirection: 'column'
            }}>
                <img id="color-band" className="color-band" src={colour_band} style={{margin: 'auto', zIndex: 0}}
                     alt="Band"/>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
                    <h1 id="manifesto-header" className="manifesto-header">MANIFESTO</h1>
                </div>
                <div id="main-box" className="main-box">
                    <div id="why-dream" className="why-dream">
                        <div className="why-dream-inner-header">
                            <h2>THE WAY WE DREAM</h2>
                        </div>
                        <div className="why-dream-inner-text">
                            <p>
                                Use your imagination, dreamboat.<br/>
                                Take a moment and just.. let it wander.<br/>
                                Daydream.<br/>
                                Through places and faces, and all the color that comes to mind.
                            </p>
                            <p>
                                Poke around a little, remind yourself what's in there.<br/>
                                Where does your imagination take you?<br/>
                                While you're at it, think of home.<br/>
                                Not the place -- the feeling.<br/>
                                You know the one.<br/>
                                *Home*.
                            </p>
                            <p>
                                We built the Arcadia because it seemed impossible until we finished it.
                            </p>
                            <p>
                                It’s an escapist journey with an unknown destination.
                            </p>
                            <p>
                                A journey that feels like home every step of the way.<br/>
                                A drifting ship full of dreamers who never have to dream alone.
                            </p>
                            <p>
                                We're all adrift in this together.<br/>
                                The Dream keeps us moving.
                            </p>
                        </div>
                    </div>
                    <div className="computer-image">
                        <img id="computer" className="computer" src={computer} style={{margin: 'auto'}}
                             alt="Computer"/>
                    </div>
                </div>
                <div id="footer-box-home" className="footer-box-home" style={{ background: '#CCBAA2' }}>
                    <Footer isFixed={false}/>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
