const getChainId = () => {
  return 1;
};

const buildMainNetUrl = (hash) => `https://etherscan.io/tx/${hash}`; // replace with opensea.io

const buildTestNetUrl = (hash) => `https://rinkeby.etherscan.io/tx/${hash}`;

const getOpenSeaTestNetUrl = () => 'https://testnets.opensea.io/account';

const getOpenSeaMainnetUrl = () => 'https://opensea.io/account';

const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export {
  getChainId,
  buildMainNetUrl,
  buildTestNetUrl,
  getOpenSeaMainnetUrl,
  getOpenSeaTestNetUrl,
  debounce,
};
