import React, {useEffect, useState} from "react";
import {AppContextProvider} from 'context/AppContext';
import {Route, Routes} from 'react-router-dom';
import './App.scss';
import {NavBar} from './components/NavBar';
import Home from 'pages/home/HomePage';
import Manifesto from 'pages/manifesto/ManifestoPage';
import FaqPage from 'pages/faq/FaqPage';

function App() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    return (
        <AppContextProvider
            value={{
                isMobile,
            }}
        >
            <React.StrictMode>
                <NavBar/>
                {
                    <div id="main-wrapper">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/manifesto" element={<Manifesto/>}/>
                            <Route path="/faq" element={<FaqPage/>}/>
                        </Routes>
                    </div>
                }
            </React.StrictMode>
        </AppContextProvider>

    );
}

export default App;
