import React, {useEffect} from 'react';

import background from "../../assets/imgs/bg.png";
import vertical_band from "../../assets/imgs/vertical_band.png";
import circle_big from "../../assets/imgs/circle-red-big.png";
import circle_small from "../../assets/imgs/circle-red-small.png";
import logo from "../../assets/imgs/logo_minimal_dark.png";
import space_ship from "../../assets/imgs/The_VHS_Arcadia-with-boost.png";
import {Link} from "react-router-dom";
import {CdlColors} from "../../themes/Colors"
import ReactFloaterJs from 'react-floaterjs'
import anime from 'animejs/lib/anime.es.js';
import Tilt from 'react-parallax-tilt';
import Footer from "../../components/footer/FooterLanding";

const HomePage = () => {
    useEffect(() => {
        const verticalBandEl = document.getElementById("vertical-band")
        const middleEl = document.getElementById("logo-box")
        const circleBigEl = document.getElementById("circle-big")
        const circleSmallEl = document.getElementById("circle-small")
        const middleLogoEl = document.getElementById("middle-logo")
        const spaceShipBoxEl = document.getElementById("space-ship-box")
        const wakeUpEl = document.getElementById("wake-up-text")
        const linkBoxEl = document.getElementById("link-box")
        const socialBoxEl = document.getElementById("social-box")
        const footerBoxEl = document.getElementById("footer-box-home")
        const mainEl = document.getElementById("main-element")

        let timer0 = setTimeout(() => {
            mainEl.style.opacity = '1'
        }, 1);

        let timer1 = setTimeout(() => verticalBandEl.classList.add("vertical-band-first-anim"), 0);

        let timer2 = setTimeout(() => {
            verticalBandEl.classList.add("vertical-band-second-anim")
        }, 200);

        let timer3 = setTimeout(() => {
            middleEl.classList.add("logo-box-first-anim")
        }, 600);

        let timer4 = setTimeout(() => circleBigEl.classList.add("circle-big-first-anim"), 1000);

        let timer5 = setTimeout(() => circleSmallEl.classList.add("circle-small-first-anim"), 1200);

        let timer6 = setTimeout(() => middleLogoEl.classList.add("middle-logo-first-anim"), 1600);

        let timer7 = setTimeout(() => circleBigEl.classList.add("circle-big-second-anim"), 1600);

        let timer8 = setTimeout(() => circleSmallEl.classList.add("circle-small-second-anim"), 1600);

        let timer9 = setTimeout(() => spaceShipBoxEl.classList.add("space-ship-box-first-anim"), 1800);

        let timer10 = setTimeout(() => wakeUpEl.classList.add("wake-up-text-first-anim"), 2000);

        let timer11 = setTimeout(() => linkBoxEl.classList.add("link-box-first-anim"), 2200);

        let timer12 = setTimeout(() => socialBoxEl.classList.add("social-box-first-anim"), 2400);

        let timer13 = setTimeout(() => footerBoxEl.classList.add("footer-box-home-first-anim"), 2600);

        return () => {
            clearTimeout(timer0);
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
            clearTimeout(timer5);
            clearTimeout(timer6);
            clearTimeout(timer7);
            clearTimeout(timer8);
            clearTimeout(timer9);
            clearTimeout(timer10);
            clearTimeout(timer11);
            clearTimeout(timer12);
            clearTimeout(timer13);
        };
    }, []);

    useEffect(() => {

        const cloud1anim = anime.timeline({loop: true});

        cloud1anim
            .add({
                targets: '#cloud1',
                opacity: [0, .7],
                duration: 10000,
                delay: 0
            })
            .add({
                targets: '#cloud1',
                translateX: ['105%', '-55vw'],
                easing: 'linear',
                duration: 13000,
                offset: '-=10000'
            })
            .add({
                targets: '#cloud1',
                opacity: [1, 0],

                offset: '-=1000',
                duration: 10000
            });

        const cloud1anim2 = anime.timeline({loop: true,});

        cloud1anim2
            .add({
                targets: '#cloud2',
                opacity: [0, .6],
                duration: 10000,
                delay: 7200
            })
            .add({
                targets: '#cloud2',
                translateX: ['105%', '-60vw'],
                easing: 'linear',
                duration: 16000,
                offset: '-=10000'
            })
            .add({
                targets: '#cloud2',
                opacity: [1, 0],

                offset: '-=1000',
                duration: 10000
            });

        const cloud1anim3 = anime.timeline({loop: true,});

        cloud1anim3
            .add({
                targets: '#cloud3',
                opacity: [0, .6],
                duration: 10000,
                delay: 11800
            })
            .add({
                targets: '#cloud3',
                translateX: ['105%', '-66vw'],
                easing: 'linear',
                duration: 18000,
                offset: '-=10000'
            })
            .add({
                targets: '#cloud3',
                opacity: [1, 0],

                offset: '-=1000',
                duration: 10000
            });

        return () => {

        };
    }, []);

    return (
        <div className="App page-home">
            <div id="main-element" style={{
                backgroundColor: CdlColors.backgroundLanding,
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                backgroundSize: '1530px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                opacity: 0,
                transition: 'all 1s linear'
            }}>
                <div id="social-box" className="social-box">
                    <a href="https://twitter.com/cdlounge" target="_blank" className="social-btn"
                       rel="noreferrer">
                        <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.2912 8.09597L15.2245 6.98374C15.0244 4.10105 16.7806 1.46804 19.5593 0.446612C20.5819 0.0834377 22.3158 0.0380413 23.4495 0.355819C23.8941 0.492009 24.7388 0.945975 25.339 1.35455L26.4283 2.10359L27.6287 1.71772C28.2956 1.51344 29.1848 1.17296 29.5849 0.945976C29.9628 0.741691 30.2963 0.628197 30.2963 0.696292C30.2963 1.08216 29.4738 2.39867 28.7847 3.12502C27.851 4.14645 28.1178 4.23724 30.0073 3.55629C31.141 3.17041 31.1632 3.17042 30.9409 3.60169C30.8076 3.82867 30.1184 4.62311 29.3849 5.34946C28.14 6.59787 28.0733 6.73406 28.0733 7.77819C28.0733 9.38977 27.3175 12.7491 26.5617 14.5877C25.1612 18.0379 22.1602 21.6015 19.1592 23.3947C14.9355 25.9142 9.31141 26.5497 4.57647 25.0744C2.99816 24.575 0.286133 23.3039 0.286133 23.0769C0.286133 23.0088 1.10863 22.918 2.10897 22.8953C4.19857 22.8499 6.28816 22.2598 8.06654 21.2156L9.26695 20.4893L7.8887 20.0126C5.93249 19.3317 4.17634 17.7655 3.73174 16.2901C3.59836 15.8134 3.64282 15.7907 4.88769 15.7907L6.17701 15.768L5.08776 15.246C3.79843 14.5877 2.62026 13.4755 2.04228 12.3406C1.61992 11.5234 1.0864 9.45786 1.24201 9.29898C1.28647 9.23088 1.7533 9.36707 2.28681 9.54866C3.82066 10.1161 4.02073 9.97993 3.13154 9.0266C1.46431 7.30152 0.953025 4.7366 1.7533 2.30788L2.1312 1.21836L3.59836 2.69375C6.59938 5.66724 10.1339 7.43771 14.1797 7.95977L15.2912 8.09597Z"
                                fill="#FF665C"/>
                        </svg>
                    </a>
                    <a href="https://discord.gg/cdlounge" target="_blank" className="social-btn"
                       rel="noreferrer">
                        <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M26.5012 25.857C26.5012 25.857 25.4004 24.5407 24.4831 23.3774C28.4887 22.2448 30.0176 19.7346 30.0176 19.7346C28.7639 20.5611 27.5714 21.1427 26.5012 21.5407C24.9723 22.1836 23.5046 22.6121 22.0675 22.857C19.1321 23.408 16.4413 23.255 14.148 22.8264C12.4051 22.4897 10.9068 21.9999 9.65309 21.5101C8.94982 21.2346 8.18539 20.8978 7.42095 20.4693C7.32922 20.4081 7.23749 20.3774 7.14575 20.3162C7.0846 20.2856 7.05403 20.255 7.02345 20.2244C6.47306 19.9183 6.16728 19.704 6.16728 19.704C6.16728 19.704 7.63499 22.1529 11.5183 23.3162C10.601 24.4794 9.46963 25.857 9.46963 25.857C2.71205 25.6427 0.143555 21.204 0.143555 21.204C0.143555 11.3469 4.54669 3.35721 4.54669 3.35721C8.94982 0.0511172 13.1389 0.142955 13.1389 0.142955L13.4447 0.510296C7.94077 2.10212 5.40285 4.52046 5.40285 4.52046C5.40285 4.52046 6.07555 4.15312 7.20691 3.63272C10.4787 2.19396 13.0778 1.796 14.148 1.70416C14.3314 1.67355 14.4843 1.64294 14.6678 1.64294C16.533 1.39804 18.6428 1.33682 20.8444 1.58172C23.7492 1.91845 26.8681 2.77558 30.0482 4.52046C30.0482 4.52046 27.6325 2.22457 22.4344 0.632748L22.8625 0.142955C22.8625 0.142955 27.0516 0.0511172 31.4547 3.35721C31.4547 3.35721 35.8578 11.3469 35.8578 21.204C35.8578 21.204 33.2588 25.6427 26.5012 25.857ZM13.1439 12.1166C11.4147 12.1166 10.0496 13.6925 10.0496 15.6151C10.0496 17.5377 11.4451 19.1136 13.1439 19.1136C14.8731 19.1136 16.2382 17.5377 16.2382 15.6151C16.2686 13.6925 14.8731 12.1166 13.1439 12.1166ZM24.2167 12.1166C22.4875 12.1166 21.1224 13.6925 21.1224 15.6151C21.1224 17.5377 22.5178 19.1136 24.2167 19.1136C25.9459 19.1136 27.311 17.5377 27.311 15.6151C27.311 13.6925 25.9459 12.1166 24.2167 12.1166Z"
                                  fill="#FF665C"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/cosmicdreamlounge" target="_blank" className="social-btn"
                       rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1606_2513)">
                                <path
                                    d="M5.49219 0.863382C6.50851 0.464022 7.67107 0.192342 9.37347 0.112022C11.08 0.0288223 11.624 0.0102622 15.9693 0.00194225C20.3146 -0.00637775 20.8627 0.0125022 22.5658 0.0861022C24.2688 0.159702 25.432 0.428182 26.4503 0.822102C27.504 1.22786 28.3975 1.77346 29.2877 2.66146C30.1779 3.54946 30.7267 4.44002 31.1379 5.49186C31.537 6.50914 31.808 7.66786 31.889 9.3741C31.9699 11.0803 31.9895 11.625 31.9978 15.9693C32.0061 20.3136 31.9885 20.8589 31.9139 22.5667C31.84 24.2685 31.5722 25.4323 31.1779 26.4499C30.7712 27.504 30.2253 28.3968 29.3386 29.2877C28.4519 30.1786 27.56 30.7271 26.5082 31.1386C25.4912 31.536 24.3351 31.8087 22.6285 31.8896C20.9219 31.9706 20.3751 31.9898 16.0314 31.9981C11.6877 32.0064 11.1395 31.9891 9.43651 31.9146C7.73347 31.84 6.56963 31.5719 5.55203 31.1786C4.49603 30.7709 3.60451 30.2259 2.71395 29.3386C1.82339 28.4512 1.27395 27.56 0.863386 26.5082C0.464026 25.4915 0.193306 24.3293 0.112026 22.6272C0.0294659 20.9203 0.0102659 20.3757 0.00194594 16.0307C-0.00637406 11.6858 0.010266 11.1383 0.0854659 9.4349C0.160666 7.73154 0.427546 6.56802 0.821466 5.54946C1.22851 4.49602 1.77475 3.60386 2.66179 2.71362C3.54883 1.82338 4.44035 1.2733 5.49219 0.863382Z"
                                    fill="#FF665C"/>
                                <path
                                    d="M22.9288 8.51494C22.7173 8.19953 22.604 7.82848 22.6034 7.44872C22.6025 6.93961 22.8039 6.45101 23.1633 6.09036C23.5226 5.72971 24.0105 5.52653 24.5196 5.52552C24.8993 5.52476 25.2708 5.63664 25.587 5.84701C25.9031 6.05738 26.1498 6.35678 26.2958 6.70736C26.4418 7.05794 26.4806 7.44395 26.4072 7.81656C26.3338 8.18916 26.1516 8.53164 25.8835 8.80067C25.6155 9.06969 25.2737 9.25319 24.9013 9.32795C24.529 9.4027 24.1429 9.36536 23.7917 9.22064C23.4406 9.07593 23.1403 8.83034 22.9288 8.51494Z"
                                    fill="#F3EBDF"/>
                                <path
                                    d="M16.0149 24.2151C11.4783 24.2238 7.79315 20.5537 7.78419 16.0161C7.77555 11.4791 11.4463 7.79336 15.9836 7.7844C20.5208 7.77544 24.2069 11.4465 24.2156 15.9841C24.2242 20.5217 20.5516 24.2065 16.0149 24.2151Z"
                                    fill="#F3EBDF"/>
                                <path
                                    d="M11.56 13.0457C10.9757 13.924 10.6649 14.9558 10.667 16.0106C10.6683 16.711 10.8076 17.4043 11.0769 18.0509C11.3461 18.6975 11.7402 19.2847 12.2364 19.779C12.7327 20.2733 13.3214 20.665 13.9691 20.9317C14.6167 21.1984 15.3106 21.335 16.011 21.3335C17.0659 21.3314 18.0964 21.0166 18.9723 20.4288C19.8482 19.841 20.5302 19.0067 20.9319 18.0313C21.3337 17.056 21.4372 15.9834 21.2293 14.9492C21.0215 13.915 20.5116 12.9657 19.7643 12.2213C19.0169 11.4769 18.0656 10.9708 17.0306 10.7671C15.9956 10.5633 14.9234 10.6711 13.9497 11.0767C12.9759 11.4823 12.1443 12.1675 11.56 13.0457Z"
                                    fill="#FF665C"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1606_2513">
                                    <rect width="32" height="32" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>

                </div>
                <div className="fogwrapper">
                    <div id="foglayer_01" className="fog">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                    <div id="foglayer_02" className="fog">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                    <div id="foglayer_03" className="fog">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                </div>
                <Tilt className="parallax-effect" tiltAxis="y"
                      trackOnWindow={true}
                      tiltMaxAngleY={6} perspective={900} transitionSpeed={1200}
                      gyroscope={true}>
                    <img id="vertical-band" className="vertical-band" src={vertical_band} style={{margin: 'auto'}}
                         alt="Band"/>
                    <div id="logo-box" className="logo-box">
                        <div className="inside-logo-box">
                            <img id="circle-big" className="circle-big" src={circle_big}
                                 style={{margin: 'auto', zIndex: 3}}
                                 alt="Circle Big"/>
                            <img id="circle-small" className="circle-small" src={circle_small}
                                 style={{margin: 'auto', zIndex: 4}}
                                 alt="Circle Small"/>
                            <a href="https://cdlounge.io" target="_blank" style={{zIndex: 5}}
                               rel="noreferrer">
                                <img id="middle-logo" className="middle-logo" src={logo} style={{margin: 'auto'}}
                                     alt="Logo"/>
                            </a>
                            <div id="space-ship-box" className="space-ship-box" style={{margin: 'auto', zIndex: 6}}>
                                <ReactFloaterJs>
                                    <img id="space-ship" className="space-ship inner-element" src={space_ship}
                                         style={{margin: 'auto', zIndex: 6}}
                                         alt="Space Ship"/>
                                </ReactFloaterJs>
                            </div>
                        </div>
                        <p id="wake-up-text" className="wake-up-text">wake up and <br/> start dreaming</p>
                    </div>
                </Tilt>
                <div id="link-box" className="link-box">
                    <Link to="manifesto">
                        <div id="manifesto-btn-1" className="link-btn">
                            MANIFESTO
                        </div>
                    </Link>
                    <Link to="faq">
                        <div id="manifesto-btn" className="link-btn">
                            FAQ
                        </div>
                    </Link>
                </div>
                <div id="footer-box-home" className="footer-box-home">
                    <Footer isFixed={false}/>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
