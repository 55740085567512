const CdlColors = {
  blue1: '#AFFDFC',
  blue2: '#63D3F8',
  blue3: '#66A1EE',
  red1: '#FF5236',
  red2: '#FF665C',
  red3: '#FE7B6A',
  orange1: '#FF9567',
  yellow1: '#FFBF71',
  yellow2: '#FFDB7D',
  yellow3: '#FFF5B2',
  dark1: '#1C1B19',
  dark2: '#282520',
  dark3: '#38342E',
  dark4: '#46413A',
  light1: '#A9967E',
  light2: '#CCBAA1',
  light3: '#EEDFC8',
  white: '#F3EBDF',
  backgroundLanding: '#66A1EE',
  backgroundManifesto: '#EEDFC8',
  backgroundFaq: '#FE7B6A'
};

export { CdlColors };
