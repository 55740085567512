import styled from 'styled-components';
import { CdlColors } from '../../themes/Colors';
import { Button } from '../Button';
import mobile_navbar from "../../assets/imgs/mobile_navbar.png";

export const AltLink = styled.span`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
`;
export const Desktop = styled.span`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledNavBar = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transform: ${props => (props.visible ? 'unset' : 'translateY(-200%)')};
  padding: 0.75rem 0;
  z-index: 450;
  transition: transform 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease,
    -webkit-box-shadow 0.4s ease;
`;

const StyledButton = styled(Button)`
  max-width: 160px;
  padding: 10px;
  font-size: 14px;
  > img {
    margin-left: 2px;
    transition: all 0.1s ease-in;
  }
  @media screen and (max-width: 1024px) {
    margin: unset;
    margin-top: 15px;
    max-width: 160px;
    width: 100%;
  }
  > div {
    display: block !important;
  }
`;
export const StyledButtonDropdown = styled.div`
  width: 100%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 0px 0px 5px 5px;
  color: #0e1c34;
  font-size: 14px;
  letter-spacing: 0.01em;
  position: absolute;
  top: 100%;
  display: none !important;
  cursor: pointer;
  > div {
    padding: 11px 20px;
    transition: all 0.1s ease-in;
    font-weight: 500;
    &:hover {
      color: ${CdlColors.candyNeon};
    }
  }
`;
export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${StyledButton} {
      > img {
        &:not(:first-child) {
          transform: rotate(180deg);
        }
      }
    }
    ${StyledButtonDropdown} {
      display: flex !important;
    }
  }
`;

const StyledNavBarDropdownItemButton = styled(Button)`
  width: 107px;
  padding: 10px;
  font-size: 14px;
  border: unset;
  font-family: 'Outage';
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-shadow: 0px 0.418303px 30px rgb(87 147 255 / 20%);
  text-transform: uppercase;
  padding: unset;
  > img {
    margin-left: 2px;
    transition: all 0.1s ease-in;
  }
  @media screen and (max-width: 1024px) {
    margin: unset;
    margin-top: 15px;
    max-width: 160px;
    width: 100%;
  }
  > div {
    display: block !important;
    &:hover {
      color: ${CdlColors.voidNeon};
    }
  }
`;
export const StyledNavBarDropdownItemButtonDropdown = styled.div`
  width: 203px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 10px;
  color: #0e1c34;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.01em;
  position: absolute;
  top: 40px;
  left: 4px;
  display: none !important;
  cursor: pointer;
  z-index: 999;
  padding: 13px 0;
  > div {
    width: 100%;
    height: 30px;
    transition: all 0.1s ease-in;
    font-weight: 600;
    color: ${CdlColors.tron1};
      > a {
        transition: unset;
        line-height: 30px;
        text-align: unset;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: ${CdlColors.tron1} !important;
        &:hover {
          background-color: ${CdlColors.tron8};
          color: ${CdlColors.tron1};
        }
    }
    &:hover {
      background-color: ${CdlColors.tron8};
      color: ${CdlColors.tron1};
    }
  }
`;

export const StyledNavBarDropdownItemButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${StyledNavBarDropdownItemButton} {
      > img {
        &:not(:first-child) {
          transform: rotate(180deg);
        }
      }
    }
    ${StyledNavBarDropdownItemButtonDropdown} {
      display: flex !important;
    }
  }
`;

const NavBarItems = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 75px 0 100px;

  @media screen and (max-width: 1024px) {
    height: 42px;
    padding: 0 75px 0 22px;
  }
`;
const CloseMenuButton = styled.div`
  width: 35px;
  margin-left: 5px;
  font-size: 33px;
  color: ${CdlColors.blue2};
  position: relative;
  top: -8px;
  right: 0;
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  justify-self: end;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  opacity: 0;
  transition: all 2.8s ease-out;

  a {
    /* margin-left: 50px; */
    font-family: 'Josefin Sans',sans-serif;
    font-weight: 700;
    text-shadow: 0px 0.418303px 30px rgba(87, 147, 255, 0.2);
    transition: color 0.2s ease-out;
    text-decoration: none;
    margin-right: 1.5rem;
    white-space: nowrap;
    text-align: center;
    font-style: normal;
    font-size: 18px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${CdlColors.dark1};
    display: inline-block;
    position: relative;
    border-bottom: 5px transparent solid;
    
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 5px;
      bottom: -5px;
      left: 0;
      background-color: ${CdlColors.yellow1};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    
    &:hover:after, &.active {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &.active {
      border-bottom: 5px ${CdlColors.yellow1} solid;
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  
  .social-box {
    a {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 5px;
        bottom: -5px;
        left: 0;
        background-color: transparent;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      
      &:hover:after, &.active {
        transform: unset;
        transform-origin: unset;
      }

      &:nth-child(1) {
        padding-top: 6px;
      }

      &:nth-child(2) {
        padding-top: 5px;
      }

      &:nth-child(3) {
        padding-top: 5px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    a {
      margin-left: 1.5rem;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 0 120px;
    background-color: ${CdlColors.dark1};
    box-shadow: 0 0 35px rgb(171 155 233 / 25%);
    height: 100vh;
    z-index: 4;
    transform: ${props => (props.toggleOn ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;
    background-image: url(${mobile_navbar});
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    margin-top: 0;

    a {
      width: calc(100% - 48px);
      height: 42px;
      background-color: ${CdlColors.white};
      transition: all 0.4s ease-out;
      margin: 0 24px;
      box-shadow: 6.48438px 6.48438px 12.9688px rgba(2, 5, 7, 0.25);
      border-radius: 5px;
      line-height: 42px;

      &:nth-child(1) {
        margin-right: 24px !important;
      }

      &:nth-child(2) {
        margin-top: 29px !important;
      }

      &:nth-child(3) {
        margin-top: 29px !important;
        margin-right: 24px !important;
      }
      
      @media screen and (max-height: 450px) {
        margin: 0 0 30px;
      }
    }

    .social-box {
      a {
        width: 50px;
        height: 50px;
        background-color: ${CdlColors.white};
        transition: all 0.4s ease-out;
        box-shadow: 6.48438px 6.48438px 12.9688px rgba(2, 5, 7, 0.25);

        &:nth-child(1) {
          padding-top: 6px;
          margin-top: 15px !important;
          margin-right: 5px !important;
        }

        &:nth-child(2) {
          padding-top: 5px;
          margin-top: 15px !important;
          margin-right: 5px !important;
        }

        &:nth-child(3) {
          padding-top: 5px;
          margin-top: 15px !important;
        }

        @media screen and (max-height: 450px) {
          margin: 0 0 30px;
        }
      }
    }
  }

  /* @media screen and (max-height: 550px) {
    height: 100vh;
    border-bottom-left-radius: 0px;
  } */
`;

const Divider = styled.img`
  margin-right: 20px;
  margin-left: 20px;
  @media screen and (max-width: 1024px) {
    transform: rotate(90deg);
    position: relative;
    left: 46%;
    margin: unset;
  }
`;

const Logo = styled.div`
  position: relative;
  display: inline-block;
  z-index: 5;
  width: 190px;
  height: auto;

  img {
    opacity: 0;
    position: absolute;
    transition: all 2.8s ease-out;
    width: 190px;
    height: auto;
    top: -20px;
  }

  @media screen and (max-width: 1300px) {
    width: 185px;
    left: 2px;
    margin-left: 0;

    img {
      top: -20px;
      width: 110px;
      height: auto;
      object-fit: contain;
      object-position: center;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;
  @media screen and (max-width: 1300px) {
    margin-left: 2%;
  }

  div {
    display: flex;
    @media screen and (max-width: 1024px) {
      display: flex;
      margin: 0 auto;
    }

    a {
      color: ${CdlColors.white};
      width: 50px;
      height: 50px;
      background: ${CdlColors.white};
      border-radius: 50%;

      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;
      align-self: center;
      letter-spacing: 0.2em;
      color: ${CdlColors.white};
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        svg {
          path {
            fill: ${CdlColors.white} !important;
          }

          g {
            path:nth-child(1) {
              fill: ${CdlColors.white} !important;
            }

            path:nth-child(2) {
              fill: ${CdlColors.red2} !important;
            }

            path:nth-child(3) {
              fill: ${CdlColors.red2} !important;
            }

            path:nth-child(4) {
              fill: ${CdlColors.white} !important;
            }
          }
        }

        background: ${CdlColors.red2};
        box-shadow: 0 0 0 6px rgba(243, 235, 223, 0.2),
        0 0 0 12px rgba(243, 235, 223, 0.1);
        transition: all 0.2s ease-out;
      }

      a {
        display: flex;
        align-items: center;
        text-align: center;
        align-self: center;
      }
    }
  }

  a {
    color: ${CdlColors.white};
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
    display: block;
  }

  @media screen and (max-width: 640px) {
    .social {
      &:first-of-type {
      }
    }
  }
`;

const Toggle = styled.div`
  display: none;
  position: absolute;
  right: 30px;
  top: 12px;
  width: ${({ toggleOn }) => (toggleOn ? '35px' : '30px')};
  height: 30px;
  padding: 3px;
  margin: 6px 0 0 20px;
  z-index: 6;

  span {
    position: absolute;
    right: 0;
    height: 7px;
    background: ${CdlColors.dark2};
    border: 2px ${CdlColors.blue2} solid;

    &:nth-child(1) {
      top: 6px;
      width: 26px;
    }
    &:nth-child(2) {
      top: 15px;
      width: 26px;
    }
    &:nth-child(3) {
      top: 24px;
      width: 26px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const WrapperNotionIcon = styled.div`
  height: 40px;
  width: 40px;
  dispay: flex;
  align-items: center;
  justify-content: center;
`;

const NavBarItemNewButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 27px;
  width: 27px;
  height: 13px;
  background: #56F6CE;
  border-radius: 2px;
  margin-left: 13px;
  
  > span {
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
  }
`;

export {
  StyledNavBar,
  NavBarItems,
  Logo,
  Nav,
  Links,
  Toggle,
  Divider,
  StyledButton,
  CloseMenuButton,
  WrapperNotionIcon,
  StyledNavBarDropdownItemButton,
  NavBarItemNewButton
};
